import React, { useState, useRef } from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Section from "../components/Section";
import { Form, Row, Col, Input, Button, FormGroup } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons/faCheckCircle";
import { Link } from "gatsby";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";

const Banner = () => {
  return (
    <Section className="banner-section">
      <div className="banner-wrapper">
        <div className="banner-content">
          <h1>
            A <span>Customer-First</span> way of fulfilling your shipping needs{" "}
          </h1>
          <p>
            ENS Logistics offers businesses a clear understanding of
            transportation and shipping options. We are inspired to make
            changes, solve problems, and increase efficiencies for your
            business.
          </p>
        </div>
      </div>
    </Section>
  );
};

const ThirdPartyService = () => {
  return (
    <Section className="third-party-service">
      <Row>
        <Col lg={6} xs={12}>
          <h1>Third Party Logistics Services</h1>
          <p>
            ENS Logistics has teamed up with partner carriers to provide
            competitive pricing and outstanding service to customers across
            America, Our dedicated transportation experts, with many years of
            experience, will provide you with a customized approach to
            fulfilling your shipping needs. As a Broker we are able to directly
            match your freight and scheduling requirements with the best
            solution for what is needed at a very reasonable price.
          </p>
        </Col>
        <Col lg={6} xs={12}>
          <Row className="tp-items">
            <Col lg={4}>
              <div className="tp-item">
                <h4>Flatbeds &amp; Specialized Equipment</h4>
                <Button tag={Link} to="/services">
                  Click for More Info
                </Button>
              </div>
            </Col>
            <Col lg={4}>
              <div className="tp-item">
                <h4>Truck Load</h4>
                <Button tag={Link} to="/services">
                  Click for More Info
                </Button>
              </div>
            </Col>
            {/* <Col lg={4}>
              <div className="tp-item">
                <h4>Less Than Truck Load</h4>
                <Button tag={Link} to="/services">
                  Click for More Info
                </Button>
              </div>
            </Col> */}
          </Row>
        </Col>
      </Row>
    </Section>
  );
};

const ContactUs = () => {
  const recaptchaRef = useRef();
  const initialContactState = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    message: "",
    "_email.from": "Freight Genie"
  };
  const [contact, setContact] = useState(initialContactState);
  const [messageSent, setMessageSent] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [error, setError] = useState("");

  const handleChange = e => {
    const { name, value } = e.target;

    const newState = Object.assign({}, contact);
    newState[name] = value;
    setContact(newState);
  };

  const sendEmail = payload => {
    const formsparkFormId = process.env.GATSBY_FORMSPARK_FORM_ID;

    return axios.post(`https://submit-form.com/${formsparkFormId}`, payload);
  };

  const handleRecaptchaChange = value => {
    if (value) {
      setError("");
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setError("");
    try {
      const recaptchaResponse = recaptchaRef.current.getValue();
      if (!recaptchaResponse) {
        setError("Please complete the captcha");
        return;
      }
      setIsSending(true);
      await sendEmail({
        ...contact,
        "g-recaptcha-response": recaptchaResponse
      });
      setMessageSent(true);
    } catch (err) {
      console.error(err);
      setError("Something went wrong!");
    } finally {
      setIsSending(false);
    }
  };

  const recaptchaSiteKey = process.env.GATSBY_RECAPTCHA_SITE_KEY;
  return (
    <Section className="contact-us" id="contact-us">
      {messageSent ? (
        <h1 className="text-secondary text-center">
          <FontAwesomeIcon icon={faCheckCircle} /> Message sent successfully!
        </h1>
      ) : (
        <>
          <h3>Looking For More Info About Our Services?</h3>
          <p className="mb-5">Contact ENS Logistics</p>
          <Form onSubmit={handleSubmit}>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Input
                    name="firstName"
                    id="firstName"
                    placeholder="First Name"
                    required
                    onChange={handleChange}
                    value={contact.firstName}
                    disabled={isSending}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Input
                    name="lastName"
                    id="lastName"
                    placeholder="Last Name"
                    required
                    onChange={handleChange}
                    value={contact.lastName}
                    disabled={isSending}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Input
                    type="tel"
                    name="phoneNumber"
                    id="phoneNumber"
                    placeholder="Phone Number"
                    required
                    onChange={handleChange}
                    value={contact.phoneNumber}
                    disabled={isSending}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Email Address"
                    required
                    onChange={handleChange}
                    value={contact.email}
                    disabled={isSending}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col>
                <FormGroup>
                  <textarea
                    className="form-control"
                    name="message"
                    id="message"
                    placeholder="Type your message here"
                    required
                    onChange={handleChange}
                    value={contact.message}
                    disabled={isSending}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  size="normal"
                  sitekey={recaptchaSiteKey}
                  onChange={handleRecaptchaChange}
                />
              </Col>
            </Row>
            {error && (
              <Row>
                <Col>
                  <p className="text-danger pt-2 d-flex justify-content-start">
                    {error}
                  </p>
                </Col>
              </Row>
            )}
            <Button
              size="lg"
              type="submit"
              color="secondary"
              disabled={isSending}
              className="float-right mt-3"
            >
              {isSending ? (
                "Sending..."
              ) : (
                <>
                  Send <span className="text-primary">Message</span>
                </>
              )}
            </Button>
            <div className="clearfix" />
          </Form>
        </>
      )}
    </Section>
  );
};

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Banner />
    <ThirdPartyService />
    <ContactUs />
  </Layout>
);

export default IndexPage;
